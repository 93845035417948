import Vue from 'vue'
import Router from 'vue-router'
import Layout from '@/layout'
Vue.use(Router)
export const routes = [
	{
		path: '/wenjuanList',
		component: () => import('@/view/wenjuan/wenjuanList'),
		name: 'WenjuanList',
		meta: {
			title: '问卷收集',
			noCache: true,
			permission: '',
			parent: '问卷中心'
		}
	},
	{
		path: '/userList',
		component: () => import('@/view/user/userList'),
		name: 'UserList',
		meta: {
			title: '用户列表',
			noCache: true,
			permission: '',
			parent: '用户管理'
		}
	},
	{
		path: '/contentList',
		component: () => import('@/view/content/list'),
		name: 'contentList',
		meta: {
			title: '内容列表',
			noCache: true,
			permission: '',
			parent: '内容管理'
		}
	},
	{
		path: '/info',
		component: () => import('@/view/content/info'),
		name: 'contentInfo',
		meta: {
			title: '内容列表',
			noCache: true,
			permission: '',
			parent: '内容管理'
		}
	},
	{
		path: '/buyList',
		component: () => import('@/view/learn/buyList'),
		name: 'buyList',
		meta: {
			title: '购买记录',
			noCache: true,
			permission: '',
			parent: '服务管理'
		}
	}, {
		path: '/serviceList',
		component: () => import('@/view/learn/serviceList'),
		name: 'serviceList',
		meta: {
			title: '服务列表',
			noCache: true,
			permission: '',
			parent: '服务管理'
		}
	},
	{
		path: '/plan',
		component: () => import('@/view/learn/plan'),
		name: 'plan',
		meta: {
			title: '学习计划',
			noCache: true,
			permission: '',
			parent: '学习管理'
		}
	},
	{
		path: '/clock',
		component: () => import('@/view/learn/clock'),
		name: 'clock',
		meta: {
			title: '每日打卡',
			noCache: true,
			permission: '',
			parent: '学习管理'
		}
	},
	{
		path: '/clockList',
		component: () => import('@/view/learn/clockList'),
		name: 'clockList',
		meta: {
			title: '打卡记录',
			noCache: true,
			permission: '',
			parent: '学习管理'
		}
	},
	{
		path: '/package',
		component: () => import('@/view/learn/package'),
		name: 'package',
		meta: {
			title: '服务套餐',
			noCache: true,
			permission: '',
			parent: '服务管理'
		}
	},
	{
		path: '/packageInfo',
		component: () => import('@/view/learn/packageInfo'),
		name: 'packageInfo',
		meta: {
			title: '服务详情',
			noCache: true,
			permission: '',
			parent: '服务管理'
		}
	},
	{
		path: '/offlineList',
		component: () => import('@/view/staging/offlineList'),
		name: 'offlineList',
		meta: {
			title: '线下预约',
			noCache: true,
			permission: '',
			parent: '工作台'
		}
	},
	{
		path: '/reservationList',
		component: () => import('@/view/staging/reservationList'),
		name: 'reservationList',
		meta: {
			title: '预约列表',
			noCache: true,
			permission: '',
			parent: '工作台'
		}
	},
	{
		path: '/overview',
		component: () => import('@/view/staging/overview'),
		name: 'overview',
		meta: {
			title: '服务概览',
			noCache: true,
			permission: '',
			parent: '工作台'
		}
	},
]

export const adminRoutes = [
	{
		path: '/jueseList',
		component: () => import('@/view/permision/jueseList'),
		name: 'jueseList',
		meta: {
			title: '角色列表',
			noCache: true,
			permission: '',
			parent: '员工管理'
		}
	},
	{
		path: '/yuangongList',
		component: () => import('@/view/permision/yuangongList'),
		name: 'YuangongList',
		meta: {
			title: '员工列表',
			noCache: true,
			permission: '',
			parent: '员工管理'
		}
	},
	// {
	// 	path: '/yuangongInfo',
	// 	component: () => import('@/view/permision/yuangongInfo'),
	// 	name: 'YuangongInfo',
	// 	meta: {
	// 		title: '员工编辑',
	// 		noCache: true,
	// 		permission: '',
	// 		parent: '员工管理'
	// 	}
	// }
]
const index = {
	path: '/index',
	component: () => import('@/view/index/index'),
	name: 'Index',
	meta: {
		title: '康皇科技智能平台',
		noCache: true,
		permission: '',
		parent: '首页'
	}
}
const threeRoutes = []
export const initRoutes = [
	{
		path: '/',
		component: Layout,
		redirect: '/index',
		name: 'Index',
		meta: {
			title: '康皇科技智能平台-后台',
			icon: 'location'
		},
		children: [index, ...routes, ...adminRoutes]
	},
	{
		path: '/h5',
		component: () => import('@/view/h5/h5Layout.vue'),
		name: 'h5',
		redirect: '/h5/index',

		meta: {
			title: '康皇科技智能平台-移动端后台',
			icon: 'location'
		},
		children: [
			{
				path: 'index',
				component: () => import('@/view/h5/index.vue'),
				name: 'h5Index',
				meta: {
					title: '康皇后台-移动端',
					noCache: true,
					permission: '',
					parent: 'h5'
				}
			},
			{
				path: 'deviceList',
				component: () => import('@/view/h5/deviceList/index.vue'),
				name: 'h5DeviceList',
				meta: {
					title: '设备清单',
					noCache: true,
					permission: '',
					parent: 'h5'
				}
			},
			{
				path: 'orderList',
				component: () => import('@/view/h5/orderList/index.vue'),
				name: 'h5OrderList',
				meta: {
					title: '订单管理',
					noCache: true,
					permission: '',
					parent: 'h5'
				}
			}]
	},
	{
		path: '/login',
		component: () => import('@/view/login'),
		name: 'Login',
		meta: {
			title: '登录'
		}
	},
	{
		path: '/404',
		component: () => import('@/view/404'),
		name: '404',
		meta: {
			title: '页面未找到'
		}
	},
	{
		path: '/401',
		component: () => import('@/view/401'),
		name: '401',
		meta: {
			title: '暂无权限'
		}
	}
	// {
	// 	path: '/aboutInfo',
	// 	component: () => import('@/view/aboutInfo'),
	// 	name: 'AboutInfo',
	// 	meta: {
	// 		title: '关于我们'
	// 	}
	// },
	// {
	// 	path: '/webview',
	// 	component: () => import('@/view/webview'),
	// 	name: 'Webview',
	// 	meta: {
	// 		title: '隐私服务'
	// 	}
	// },
	// {
	// 	path: '/policyInfo',
	// 	component: () => import('@/view/policyInfo'),
	// 	name: 'PolicyInfo',
	// 	meta: {
	// 		title: '政策详情'
	// 	}
	// }
]
const createRouter = () =>
	new Router({
		mode: 'history', // require service support
		base: '/',
		scrollBehavior(to, from, savedPosition) {
			console.log('路由滚动', savedPosition)
			// return new Promise((resolve, reject) => {
			//     setTimeout(() => {
			// 		let a = {}
			// 		if(savedPosition) {
			// 			a = savedPosition
			// 		}else{
			// 			a =  {
			// 				x: 0,
			// 				y: 0
			// 			}
			// 		}
			//       resolve(a)
			//     }, 200)
			//   })
			// let a = {}
			// if(savedPosition) {
			// 	a = savedPosition
			// }else{
			// 	a =  {
			// 		x: 0,
			// 		y: 0
			// 	}
			// }
			// return a
		},
		routes: initRoutes
	})

const router = createRouter()

export function resetRouter() {
	const newRouter = createRouter()
	router.matcher = newRouter.matcher // reset router
}
export default router
