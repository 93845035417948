let headerNav = [
	// {
	// 	name: '工作台',
	// 	path: '/staging'
	// },
	// {
	// 	name: '用户管理',
	// 	path: '/user'
	// },
	{
		name: '学习管理',
		path: '/learn'
	},
	// {
	// 	name: '内容管理',
	// 	path: '/content'
	// },
	// {
	// 	name: '问卷中心',
	// 	path: '/wenjuan'
	// },
	// {
	// 	name: '员工管理',
	// 	path: '/permision'
	// }
]

let sidebarList = [
	// [
	// 	{
	// 		name: '服务概览',
	// 		path: '/overview',
	// 		parent: '工作台'
	// 	},
	// 	{
	// 		name: '预约列表',
	// 		path: '/reservationList',
	// 		parent: '工作台'
	// 	},
	// 	{
	// 		name: '线下预约',
	// 		path: '/offlineList',
	// 		parent: '工作台'
	// 	}
	// ],
	// [
	// 	{
	// 		name: '用户列表',
	// 		path: '/userList',
	// 		parent: '用户管理'
	// 	}
	// ],
	[
		{
			name: '学习计划',
			path: '/plan',
			parent: '学习管理'
		},
		{
			name: '每日打卡',
			path: '/clock',
			parent: '学习管理'
		},
		{
			name: '打卡记录',
			path: '/clockList',
			parent: '学习管理'
		},
	],
	// [
	// 	{
	// 		name: '内容管理',
	// 		path: '/contentList',
	// 		parent: '内容管理'
	// 	}
	// ],
	// [
	// 	{
	// 		name: '问卷收集',
	// 		path: '/wenjuanList',
	// 		parent: '问卷中心'
	// 	},
	// ],
	// [
	// 	{
	// 		name: '员工列表',
	// 		path: '/yuangongList',
	// 		parent: '员工管理'
	// 	},
	// 	{
	// 		name: '角色列表',
	// 		path: '/jueseList',
	// 		parent: '员工管理'
	// 	}
	// ]
]
let sidebarList2 = [
	// [
	// 	{
	// 		name: '服务概览',
	// 		path: '/overview',
	// 		parent: '工作台'
	// 	},
	// 	{
	// 		name: '预约列表',
	// 		path: '/reservationList',
	// 		parent: '工作台'
	// 	},
	// 	{
	// 		name: '线下预约',
	// 		path: '/offlineList',
	// 		parent: '工作台'
	// 	}
	// ],
	// [
	// 	{
	// 		name: '用户列表',
	// 		path: '/userList',
	// 		parent: '用户管理'
	// 	}
	// ],
	[
		{
			name: '学习计划',
			path: '/plan',
			parent: '学习管理'
		},
		{
			name: '每日打卡',
			path: '/clock',
			parent: '学习管理'
		},
		{
			name: '打卡记录',
			path: '/clockList',
			parent: '学习管理'
		},
	],
	// [
	// 	{
	// 		name: '内容管理',
	// 		path: '/contentList',
	// 		parent: '内容管理'
	// 	}
	// ],
	// [
	// 	{
	// 		name: '问卷收集',
	// 		path: '/wenjuanList',
	// 		parent: '问卷中心'
	// 	},
	// ],
	// [
	// 	{
	// 		name: '员工列表',
	// 		path: '/yuangongList',
	// 		parent: '员工管理'
	// 	},
	// 	{
	// 		name: '角色列表',
	// 		path: '/jueseList',
	// 		parent: '员工管理'
	// 	}
	// ]
]
let h5SidebarList = [
	{
		name: '设备清单',
		path: '/h5/deviceList',
		parent: 'h5'
	}, {
		name: '订单管理',
		path: '/h5/orderList',
		parent: 'h5'
	}
]
export { headerNav, sidebarList, sidebarList2, h5SidebarList }
